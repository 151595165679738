import { Application, ApplicationStatusId } from "src/types/Application";

export function computeReadyToFinish(data: Application) {
  const applicationStatusId = data.applicationStatusId;
  const cancelled =
    data && applicationStatusId === ApplicationStatusId.Cancelled;

  const completed = data
    ? [
        ApplicationStatusId.LeaseSigned,
        ApplicationStatusId.LeaseExpired,
        ApplicationStatusId.LeaseDeclined,
      ].includes(applicationStatusId)
    : false;

  const readyToFinishCount = cancelled || completed ? 1 : 0;

  return readyToFinishCount;
}
