import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  ApplicationStatusId,
  GetApplicationsBody,
  ReduxApplication,
} from "src/types/Application";
import {
  GetFilterOptionsBody,
  UserFilterSelections,
} from "src/types/FilterOptions";
import { HelpRequest, PostHelpRequestBulkFetch } from "src/types/HelpRequests";
import { Configuration, Notification, ReduxState } from "src/types/Redux";
import { computeReadyToFinish } from "src/utils/Application";

const initialState: ReduxState = {
  isFetchingApplications: true,
  applications: [],
  helpRequests: [{}],
  application: {},
  selectedActionId: null,
  filterOptions: {
    userFilterSelections: {},
  },
  notification: undefined,
  configuration: undefined,
};

const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setApplications(state, action: PayloadAction<GetApplicationsBody>) {
      const lastFetchedDate = new Date().toJSON();
      // set the ready to finish and inc stale here
      const apps = action.payload.applications.map((x) => {
        // ready to finish
        x.readyToFinishCount = computeReadyToFinish(x);

        // last refreshed application data
        x.lastFetchedDate = lastFetchedDate;

        // incomplete stale
        const incTimeMin = 64800000; // 18hrs
        const greaterThanIncTimeMin =
          x.modifiedDate &&
          new Date().getTime() - new Date(x.modifiedDate).getTime() >
            incTimeMin;
        const incAppStatus = [
          ApplicationStatusId.InviteSent,
          ApplicationStatusId.InProgress,
          ApplicationStatusId.ActionRequired,
        ].includes(x.applicationStatusId);
        const incGroupStatus = [
          2 /* Approved With Conditions */, 3 /* Declined */,
        ].includes(x.applicationScreeningResultId);

        x.incStaleItemsCount =
          greaterThanIncTimeMin && (incAppStatus || incGroupStatus) ? 1 : 0;

        // remove dashes and capitalize
        x.communityName = (x.communityName ?? "Unknown")
          .replace(/-/g, " ")
          .split(" ")
          .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
          .join(" ");

        x.completeUnitId = `${x.phaseId}-${x.buildingId}-${x.unitId}`;

        return x;
      });

      return {
        ...state,
        applications: apps,
        isFetchingApplications: false,
        lastFetchedDate,
      };
    },
    setApplication(state, action: PayloadAction<ReduxApplication>) {
      action.payload.completeUnitId = `${action.payload.phaseId}-${action.payload.buildingId}-${action.payload.unitId}`;
      action.payload.readyToFinishCount = computeReadyToFinish(action.payload);
      action.payload.lastFetchedDate = new Date().toJSON();
      return {
        ...state,
        application: action.payload,
      };
    },
    setInitialFilterOptions(
      state,
      action: PayloadAction<GetFilterOptionsBody>
    ) {
      // filter development communities
      const regexFilter = /DV/gi;
      action.payload.communities =
        action.payload.communities?.filter(
          ({ communityId }) => !communityId.match(regexFilter)
        ) ?? [];

      state.filterOptions = action.payload;
    },
    setFilterOptions(state, action: PayloadAction<UserFilterSelections>) {
      state.filterOptions.userFilterSelections = {
        ...state.filterOptions.userFilterSelections,
        ...action.payload,
      };
      state.isFetchingApplications = true;
    },
    setSearchTerms(state, action) {
      return {
        ...state,
        searchTerms: action.payload,
      };
    },
    setActionRequiredId(state, action) {
      if (action.payload === state.selectedActionId) {
        return {
          ...state,
          selectedActionId: null,
        };
      } else {
        return {
          ...state,
          selectedActionId: action.payload,
        };
      }
    },
    setHelpRequests(state, action: PayloadAction<PostHelpRequestBulkFetch>) {
      // current data
      const currentHelpRequests: HelpRequest[] = [...state.helpRequests];
      const applications = state.applications;
      const incomingHelpRequests = action.payload.helpRequests;

      incomingHelpRequests.forEach((helpRequest) => {
        const isNew = helpRequest.helpStatus === "NEW";

        // get current help requests
        const current = currentHelpRequests.filter(
          (x: any) => x.applicationId === helpRequest.applicationId
        );

        if (current.length === 0) {
          if (!isNew) return;
          currentHelpRequests.push({
            applicationId: helpRequest.applicationId,
            // @ts-expect-error
            helpRequestList: [helpRequest],
          });
        } else {
          // @ts-expect-error
          const { helpRequestList } = current[0];
          let isMatchFound = false;

          // replace
          for (let ctr = 0; ctr < helpRequestList.length; ctr++) {
            if (helpRequestList[ctr].id === helpRequest.id) {
              helpRequestList[ctr] = helpRequest;
              isMatchFound = true;
              break;
            }
          }

          if (!isMatchFound) {
            if (!isNew) return;
            helpRequestList.push(helpRequest);
          }
        }
      });

      // count help requests
      currentHelpRequests.forEach((helpRequest: any) => {
        const application = applications.findIndex((x) => {
          return x.applicationId === helpRequest.applicationId;
        });

        if (application < 0) return;

        const totalHelpRequestsCount =
          helpRequest?.helpRequestList?.length ?? 0;
        applications[application].helpRequestCount = totalHelpRequestsCount;
      });

      state.helpRequests = [...currentHelpRequests];
      state.applications = applications;
    },
    resetHelpRequests(state) {
      state.helpRequests = [{}];
    },
    removeHelpRequest(state, action) {
      // On Applications Details page
      const requestId = action.payload.id;
      const requests = state.helpRequests.map((x: any) => {
        if (x.helpRequestList) {
          x.helpRequestList.forEach((request) => {
            if (request.id === requestId) {
              request.helpStatus = "RESOLVED";
            }
          });
          return { ...x };
        } else {
          return x;
        }
      });

      // On Applications page, remove one from the application sum also so the Actions Required works correctly
      if (state.applications.length > 0) {
        let helpRequestApps = state.applications;
        const appId = action.payload.applicationId;
        const app: any = helpRequestApps.find(
          (x: any) => x.applicationId === appId
        );
        if (app) {
          app.helpRequestCount = app.helpRequestCount - 1;
        }

        state.applications = helpRequestApps;
      }
      state.helpRequests = requests;
    },
    setApplicationStatus(state, action) {
      return {
        ...state,
        applicationStatus: action.payload,
      };
    },
    setApplicationTermsAndConditions(state, action) {
      return {
        ...state,
        applicationTermsAndConditions: action.payload,
      };
    },
    setNotification(state, action: PayloadAction<Notification>) {
      return {
        ...state,
        notification: action.payload,
      };
    },
    resetNotification(state) {
      return {
        ...state,
        notification: undefined,
      };
    },
    setConfiguration(state, action: PayloadAction<Configuration>) {
      return {
        ...state,
        configuration: action.payload,
      };
    },
  },
});

export const {
  setApplications,
  setApplication,
  setInitialFilterOptions,
  setFilterOptions,
  setSearchTerms,
  setActionRequiredId,
  setHelpRequests,
  resetHelpRequests,
  removeHelpRequest,
  setApplicationStatus,
  setApplicationTermsAndConditions,
  setNotification,
  resetNotification,
  setConfiguration,
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
